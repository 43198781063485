import React, { ChangeEvent } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importieren Sie die Stile für den Snow-Theme
import "./blogEditor.css";

type EditorProps ={
  value: string;
  onChange: (content: string) => void;
  className?: string;
}

const Editor: React.FC<EditorProps> = ({ value, onChange }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"]
    ]
  };

  const handleEditorChange = (content: string, delta: any, source: string, editor: any) => {
    onChange(content);
  };

  return (
    <div className="content">
      <ReactQuill
        className="reactQuill"
        value={value}
        theme="snow"
        onChange={handleEditorChange}
        modules={modules}
      />
    </div>
  );
};

export default Editor;