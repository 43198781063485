import Home from "./routes/home/home.tsx"
import {Routes,Route} from "react-router-dom"
import Navigation from "./routes/navigation/Navbar.tsx"
import Calculator from "./routes/calculator/calorieCalculator.tsx"
import Checkout from "./routes/checkout/checkout.tsx"
import BlogPage from "./routes/BlogPage/BlogPage.tsx"
import BlogLogin from './components/blogLogin/blogLogin.tsx'
import BlogRegister from './components/blogRegister/blogRegister.tsx'
import CreatePost from "./components/createPost/createPost.tsx"
import Postpage from "./components/postPage/postPage.tsx"
import EditPost from "./components/editPost/editPost.tsx"
import BlogEditor from "./components/blogEditor/blogEditor.tsx"

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import {UserContextProvider} from './context/UserContext.tsx'
import { PurchasedPlansProvider } from "./context/PurchasedContext.tsx"


function App() {
  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);


const hours = 1; // to clear the localStorage after 1 hour
const now = new Date().getTime();
const setupTime = localStorage.getItem('setupTime');
if (setupTime == null) {
    localStorage.setItem('setupTime', now)
} else {
    if(now-setupTime > hours*60*60*1000) {
        localStorage.clear()
        localStorage.setItem('setupTime', now);
    }
}


  return (
    <PurchasedPlansProvider>
    <UserContextProvider>
    <Routes>
    <Route path="/" element={<Navigation/>}>   
    <Route index element={<Home/> }/>
    {/* <Route path="/blogeditor" element={<BlogEditor/>}/> */}
    <Route path="calorie-calculator" element={<Calculator/>}/>
    <Route path="checkout" element={<Checkout/>}/>
    {/* <Route path ="blog/*" element={<BlogPage/>}/>
    <Route path="blog/login" element={<BlogLogin />}/>
    <Route path="blog/register" element={<BlogRegister />}/> */}
    <Route path="blog/create" element={<CreatePost />}/>
    <Route path="blog/post/:id" element={<Postpage/>}/>
    <Route path="blog/edit/:id" element={<EditPost/>}/>
   
       </Route>  
  </Routes>
  </UserContextProvider>
  </PurchasedPlansProvider>
  );
}

export default App;
