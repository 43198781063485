import React, { useEffect, useState } from "react";
import Editor from '../blogEditor/blogEditor.tsx';
import BlogHeader from '../blogHeader/blogHeader.tsx';
import { useParams, useNavigate } from "react-router";
import './editPost.css'

interface PostInfo {
    title: string;
    summary: string;
    content: string;
    category: string;
}

const EditPost: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [title, setTitle] = useState<string>('');
    const [summary, setSummary] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [category, setCategory] = useState<string>('')
    const [files, setFiles] = useState<FileList | null>(null);
    const [redirect, setRedirect] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`http://localhost:4000/blog/post/${id}`)
            .then(response => {
                response.json().then((postInfo: PostInfo) => {
                    setTitle(postInfo.title);
                    setContent(postInfo.content);
                    setCategory(postInfo.category);
                    setSummary(postInfo.summary);
                });
            });
    }, [id]);

    async function updatePost(e: React.FormEvent) {
        e.preventDefault();
        const data = new FormData();
        data.set('title', title);
        data.set('summary', summary);
        data.set('content', content);
        data.set('category',category)
        data.set('id', id);
        if (files?.[0]) {
            data.set('file', files[0]);
        }

        const response = await fetch(`http://localhost:4000/blog/post/`, {
            method: 'PUT',
            body: data,
            credentials: 'include',
        });
        if (response.ok) {
            setRedirect(true);
        }
    }

    useEffect(() => {
        if (redirect) {
            navigate(`/blog/post/${id}`);
        }
    }, [redirect, navigate, id]);

    return (
        <div className="editPost">
            <form onSubmit={updatePost}>
                <input type="title"
                    placeholder={'Title'}
                    value={title}
                    onChange={e => setTitle(e.target.value)} />
               <select value={category}  onChange={e => setCategory(e.target.value)}>
                   <option value="Culturismo">Culturismo</option>
                    <option value="Powerlifting">Powerlifting</option>
                    <option value="Resistencia">Resistencia</option>
                    <option value="Alimentacion">Alimentacion</option>      
                </select>
                <input type="summary"
                    placeholder={'Summary'}
                    value={summary}
                    onChange={e => setSummary(e.target.value)} />
                <input type="file"
                    onChange={e => setFiles(e.target.files)} />
                <Editor className="reactQuill" value={content} onChange={setContent} />
                <button> Update Post </button>
            </form>
        </div>
    );
};

export default EditPost;