import React from 'react'
import { useEffect, useContext, useState} from 'react';
import { Outlet, useLocation } from 'react-router'
import Hero from '../../components/hero/Hero.tsx'
import Panels from '../../components/panels/Panels.tsx';
import Plans from '../../components/plans/Plans.tsx';
import Philosophy from '../../components/philosophy/Philosophy.tsx';
import References from '../../components/references/References.tsx';
import Certification from '../../components/certification/Certification.tsx';
import Footer from '../../components/footer/Footer.tsx';
import BookingConfirmation from '../../components/bookingConfirmation/bookingConfirmation.tsx';
import { PurchasedPlansContext} from   '../../context/PurchasedContext.tsx';



const home: React.FC = () => 


{ 


  const { purchasedPlans, setPurchasedPlans } = useContext(PurchasedPlansContext);

  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setPurchasedPlans(false);
    }, 10000); // 10 Sekunden

    return () => {
      clearTimeout(timer);
      ; // Setzt den Zustand zurück, wenn die Komponente unmountet wird oder die Location sich ändert
    };
  }, [location]);

 

  return (
    
    
    <div className="App">
    <div data-aos="fade-down">{purchasedPlans? <BookingConfirmation/>: null}</div>
    <Hero/>
    <Panels/>
    <Plans/>
    <Philosophy/>
    <References/>
    <Certification/>
    <Footer/>
    <Outlet/>
  </div>
    
  )
}

export default home