import React, { useState, useEffect, createContext, ReactNode } from 'react';

// Definiert den Typ für den Kontext
type PurchasedPlansContextType = {
  purchasedPlans: boolean;
  setPurchasedPlans: React.Dispatch<React.SetStateAction<boolean>>;
}

// Definiert den Standardwert für den Kontext
const defaultValue: PurchasedPlansContextType = {
  purchasedPlans: true, // Anfangswert für purchasedPlans
  setPurchasedPlans: () => {} // Leere Funktion als Platzhalter
};

// Erstellt den Kontext mit dem Standardwert
export const PurchasedPlansContext = createContext<PurchasedPlansContextType>(defaultValue);

// Definiert die Props für den Provider
type PurchasedPlansProviderProps = {
  children: ReactNode;
}

// Implementiert den Provider
export const PurchasedPlansProvider: React.FC<PurchasedPlansProviderProps> = ({ children }) => {
  // Lädt den initialen Wert aus dem localStorage oder setzt ihn auf false
  const initialPurchasedPlans = JSON.parse(localStorage.getItem('purchasedPlans') || 'true');
  // Setzt den State für purchasedPlans
  const [purchasedPlans, setPurchasedPlans] = useState<boolean>(false);

  // Speichert den Wert von purchasedPlans im localStorage, wenn er sich ändert
  useEffect(() => {
    localStorage.setItem('purchasedPlans', JSON.stringify(purchasedPlans));
  }, [purchasedPlans]);

  // Gibt den Provider zurück, der den Kontextwert an seine Kinder weitergibt
  return (
    <PurchasedPlansContext.Provider value={{ purchasedPlans, setPurchasedPlans }}>
      {children}
    </PurchasedPlansContext.Provider>
  );
};
