import React, { createContext, useState, ReactNode } from "react";

type UserInfo = {
  id: string;
  Username: string;
  password: string;
}

type UserContextType = {
  userInfo: UserInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>;
}

const initialUserContext: UserContextType = {
  userInfo: { Username: "", password: "", id:"" },
  setUserInfo: () => {},
};

export const UserContext = createContext<UserContextType>(initialUserContext);

type UserContextProviderProps = {
  children: ReactNode;
}

export const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo>({
    Username: "",
    password: "",
    id:"",
  });

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};