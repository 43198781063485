import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Editor from '../blogEditor/blogEditor.tsx'

const CreatePost: React.FC = () =>  {
  const [title, setTitle] = useState<string>('');
  const [summary, setSummary] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [category, setCategory] = useState<string>('')
  const [files, setFiles] = useState<any>('');
  const navigate = useNavigate();

  async function createNewPost(ev) {
    if(!files) {
      alert('You need to upload a file as Photo');
      ev.preventDefault();
    } else {
      const data = new FormData();
      data.set('title', title);
      data.set('summary', summary);
      data.set('content', content);
      data.set('category', category)
      data.set('file', files[0]);

      ev.preventDefault();
      const response = await fetch('http://localhost:4000/blog/post', {
        method: 'POST',
        body: data,
        credentials: 'include',
      });
      if (response.ok) {
        navigate('/blog');
      }
    }
  }

  return (
    <div>
      <form onSubmit={createNewPost}>
        <input type="title" 
               placeholder={'Title'} 
               value={title} 
               onChange={e => setTitle(e.target.value)}/>
        {/* <input type="category"
               placeholder={'Category'}
               value={category}
               onChange={e => setCategory(e.target.value)}/> */}
        <input type="summary" 
               placeholder={'Summary'}
               value={summary}
               onChange={e => setSummary(e.target.value)}/>
           <select value={category}  onChange={e => setCategory(e.target.value)}>
                   <option value="Culturismo">Culturismo</option>
                    <option value="Powerlifting">Powerlifting</option>
                    <option value="Resistencia">Resistencia</option>
                    <option value="Alimentacion">Alimentacion</option>      
                </select>
        <input type="file" 
               onChange={e => setFiles(e.target.files)} />
        <Editor className="reactQuill" value={content} onChange={setContent} />
        <button> Create Post </button>
      </form>
    </div>
  );
}

export default CreatePost;