import React, { useState, useEffect } from 'react';
import './checkout.scss';
import { faEnvelope, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free/css/all.css';
import Footer from '../../components/footer/Footer.tsx';
import EmailFormular from '../../components/emailFormular/emailFormular.tsx';
import Modal from 'react-modal';


type Plan = {
  name: string;
  price: string; // hier könnte auch ein spezifischerer Typ wie `number` sein
}

const Checkout : React.FC = () => {
  const [plans, setPlans] = useState([]);
  const [rendered, setRendered] =useState<Boolean>(false)
  const [buyModalIsOpen, setBuyModalIsOpen] = useState<Boolean>(false);
  const [emailModalIsOpen, setEmailModalIsOpen] = useState<Boolean>(false)

  
  useEffect(() => {
    const selectedPlans: Plan[] = JSON.parse(localStorage.getItem('selectedPlans') || '[]');
    setPlans(selectedPlans);
    setRendered(true)
  }, []);

  useEffect(() => {
    if(plans.length === 0 && rendered === true)
      window.location.href = '/#servicios'
  }, [plans])

  const handleRemovePlan = (index : number) => {
    const newPlans = [...plans];
    newPlans.splice(index, 1);
    localStorage.setItem('selectedPlans', JSON.stringify(newPlans));
    setPlans(newPlans);
  }

  
  const total = plans.reduce((sum, plan) => {
    const price = parseFloat(plan.price.match(/\d+(\.\d+)?/)[0]);
    return sum + price;
  }, 0);


  const buyModalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },

    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


  const emailModalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },

    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };



  const closeBuyModal = () => {
    setBuyModalIsOpen(false)
  }

  const goBackToPlans = () => {
    setBuyModalIsOpen(false);
    window.location.href = '/#servicios'
  };

  const openEmailModal = () => {
    setEmailModalIsOpen(true)
  }



  // useEffect, um das Modal beim Laden der Seite automatisch zu öffnen
  useEffect(() => {
    setBuyModalIsOpen(true);
  }, []); // Leeres Array als Abhängigkeit bedeutet, dass der Effekt nur einmal beim Laden der Komponente ausgeführt wird

  return (
    
    <div>
      <div className="checkout__Container">
        <h2 className='checkout__Heading'>Planes seleccionados:</h2>
        <ul className="checkout__List">
          {plans.map((plan, index) => (
            <li key={`${plan.name}-${plan.price}`} className="checkout__Item">
              <div className="checkout__Info">
                <div className="checkout__Name">{plan.name}</div>
                <div className="checkout__Price">{plan.price}</div>
              </div>
              <div onClick={() => handleRemovePlan(index)}>
                <FontAwesomeIcon icon={faTimesCircle} color='black' cursor='pointer' size="xl" />
              </div>
            </li>
          ))}
        </ul>
        <div className="checkout__Details">
          <div className="checkout__Total">Total: RD${total.toFixed(2)}</div>
          <div className="checkout__Button">
            <button  onClick={openEmailModal}> Continuar</button>
          </div>
        </div>
        
        <Modal
        isOpen={buyModalIsOpen}
        onRequestClose={closeBuyModal}
        style={buyModalStyle}
        contentLabel="Noch etwas kaufen?"
      >

        {/* Inhalt des Modals */}
        <p className='checkout__Modal-text'>¿Le gustaría añadir más artículos?</p>
        <button className='checkout__Modal-button' onClick={goBackToPlans}>Si</button>
        <button className='checkout__Modal-button' onClick={closeBuyModal}>No, he terminado. </button>
      </Modal>


      <Modal
        isOpen={emailModalIsOpen}
        onRequestClose={closeBuyModal}
        style={emailModalStyle}
        contentLabel="Noch etwas kaufen?"
      >

        {/* Inhalt des Modals */}
        <EmailFormular
        plans = {plans}
        />
      </Modal>

      </div>
      <Footer />
     
    </div>
  )
}

export default Checkout;