import {useRef, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import React from 'react';
import emailjs from '@emailjs/browser';
import './emailFormular.scss'
import { PurchasedPlansContext} from   '../../context/PurchasedContext.tsx';


const EmailFormular= ({plans}) => {


const {setPurchasedPlans} = useContext(PurchasedPlansContext);
const navigate = useNavigate(); // Verwenden von useNavigate

const cancelSubmit = (e) => {
      e.preventDefault()
      window.location.href = '/checkout'
    }


    const form = useRef();
    
    
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('default_service', 'contact_form', form.current, {
          publicKey: 'VdQLxaLL4S_nGHaA5',
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
        setPurchasedPlans(true)
        navigate('/');
    };

    return(
        <form ref={form} onSubmit={sendEmail}>
          
        <label>Name</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <div className='emailServices'>
        <label>Selected Services:</label>
        <textarea 
        name="user_message"
        readOnly
        className="checkout-input"
        value={plans.map((plan) => `${plan.name}: ${plan.price}`).join("\n")}
        />
        </div>
        <button  className="emailButton" type='submit'>Send</button>
        <button className="emailButton" onClick={cancelSubmit}> Cancel</button>
      </form>
    )
}

export default EmailFormular