import React from 'react'
import './calorieCounter.styles.scss'
import { useState } from 'react'
import Footer from '../../components/footer/Footer.tsx'

const CalorieCalculator : React.FC = () => {


  const [weight, setWeight] = useState<number>(70); 
  const [height, setHeight] = useState<number>(170); 
  const [age, setAge] = useState<number>(25); 
  const [gender, setGender] = useState<string>("male"); 
  const [activityLevel, setActivityLevel] = useState<number>(1.2); 
  const [trainingsPerWeek, setTrainingsPerWeek] = useState<number>(1); 
  const [goal, setGoal] = useState<string>("4");



  const calculateBMR = () => {
    let bmr = 0;
    if (gender === "male") {
      
      bmr = 66.5 + 13.8 * weight + 5 * height - 6.8 * age;
    } else {
      bmr = 655.1 + 9.6 * weight + 1.9 * height - 4.7 * age;
    }
    let totalCalories = Math.round(bmr* activityLevel)
  
  if (goal === '1') {
    totalCalories -= 600;
  } else if (goal === '2') {
    totalCalories += 300;
  } else if (goal === '3') {
    totalCalories -= 300;
  }
  totalCalories += 50*trainingsPerWeek
  return totalCalories;
    
  };

const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => setAge(Number(event.target.value));
const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => setHeight(Number(event.target.value));
const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => setWeight(Number(event.target.value));

  const handleGenderChange = (event) => setGender(event.target.value);
  const handleActivityLevelChange = (value: string) =>
    setActivityLevel(Number(value));
  const handleTrainingsPerWeekChange = (value: number) => {
    setTrainingsPerWeek(Number(value));
  };
  return (
 
    <form >
    <h1 className="calorieCounter__title">Calculadora de calorías</h1>
    <div className="layout-module">
    <div className="box a">
      <h3 className="title">Género</h3>
      <div className=" no-uppercase">

        <select className= "input__box__gender" name="gender" value={gender} onChange={handleGenderChange}>
        <option value="male">Hombre</option>
          <option value="female">Mujer</option>
        </select>
     </div>
    </div>
    <div className="box b">
      <h3 className="title">Edad</h3>
      <input className= "input__box" type="number" name="age"  min="12" max="100" required={true as boolean}
      value={age} onChange={handleAgeChange}/>
    </div>
    <div className="box c">
      <h3 className="title">Altura</h3>
      <input className= "input__box" type="number" name="size"  min="100" max="240" required={true as boolean} value={height} onChange={handleHeightChange}/>
    </div>
    <div className="box d">
      <h3 className="title">Peso (Kg)</h3>
      <input className= "input__box" type="number" name="weight"  min="10" max="200" required={true as boolean} value={weight} onChange={handleWeightChange}/>
    </div>

    <div className="box e">
  <h3 className="title">Objetivo de formación</h3>
  <span className="optionSpecial">
  <label ><span></span>Adelgazamiento rapido</label>
  <input className="radio__Input" type="radio" name="goal" checked={goal === '1'} onChange={(e) => setGoal("1")}/>
   </span>
  <span className="optionSpecial">
  <label ><span></span>Desarrollo Muscular</label>
    <input className="radio__Input" type="radio"  name="goal" checked={goal === '2'} onChange={(e) => setGoal("2")} />
  </span>
  <span className="optionSpecial">
  <label><span></span>Definición Muscular</label>
    <input className="radio__Input" type="radio"  name="goal" checked={goal ==="3"} onChange={(e) => setGoal("3")} />
  </span>
  <span className="optionSpecial">
  <label><span></span>Fitness General</label>
    <input className="radio__Input" type="radio" name="goal" checked={goal === '4'} onChange={(e) => setGoal("4")} />
  </span>
</div>

<div className="box f">
  <h3 className="title">Actividad Física</h3>
  <div className="split">
    <span className="optionSpecial">
    <label><span></span>En silla o en cama</label>
      <input
        className="radio__Input"
        type="radio"
        name="activity"
        value="1.2"
        checked={activityLevel === 1.2}
        onChange={() => handleActivityLevelChange("1.2")}
      />
    </span>
    <span className="optionSpecial">
    <label><span></span>Trabajo sentado sin moverse.</label>
      <input
        className="radio__Input"
        type="radio"
        name="activity"
        value="1.45"
        checked={activityLevel === 1.45}
        onChange={() => handleActivityLevelChange("1.45")}
      />  
    </span>
    <span className="optionSpecial">
    <label htmlFor="stehend_und_gehend"><span></span>Trabajo sentado con moverse.</label>
      <input
        className="radio__Input"
        type="radio"
        name="activity"
        value="1.65"
        checked={activityLevel === 1.65}
        onChange={() => handleActivityLevelChange("1.65")}
      />
    </span>
    <span className="optionSpecial">
    <label htmlFor="koerperlich_anstrengende_arbeit"><span></span>Trabajo de pie</label>
      <input
        className="radio__Input"
        type="radio"
        name="activity"
        value="1.85"
        checked={activityLevel === 1.85}
        onChange={() => handleActivityLevelChange("1.85")}
      />
    </span>
    <span className="optionSpecial">
    <label htmlFor="koerperlich_anstrengende_arbeit"><span></span>Trabajo  muy activo</label>
      <input
        className="radio__Input"
        type="radio"
        name="activity"
        value="2.1"
        checked={activityLevel === 2.1}
        onChange={() => handleActivityLevelChange("2.1")}
      />
    </span>
  </div>
</div>

    <div className="box g">
  <h3 className="title">Entrenamiento por semana</h3>
  <div className="weekly__Trainings">
    <span className="option">
    <label><span></span>0</label>
      <input
        className="radio__Input"
        type="radio"
        name="training"
        value="0"
        checked={trainingsPerWeek === 0}
        onChange={() => handleTrainingsPerWeekChange(0)}
      /> 
    </span>
    <span className="option">
    <label><span></span>1</label>
      <input
        className="radio__Input"
        type="radio"
        name="training"
        value="1"
        checked={trainingsPerWeek === 1}
        onChange={() => handleTrainingsPerWeekChange(1)}
      />
    </span>
    <span className="option">
    <label><span></span>2</label>
      <input
        className="radio__Input"
        type="radio"
        name="training"
        value="2"
        checked={trainingsPerWeek === 2}
        onChange={() => handleTrainingsPerWeekChange(2)}
      />
    </span>
    <span className="option">
    <label><span></span>3</label>
      <input
        className="radio__Input"
        type="radio"
        name="training"
        value="3"
        checked={trainingsPerWeek === 3}
        onChange={() => handleTrainingsPerWeekChange(3)}
      />
    </span>
    <span className="option">
    <label><span></span>4</label>
      <input
        className="radio__Input"
        type="radio"
        name="training"
        value="4"
        checked={trainingsPerWeek === 4}
        onChange={() => handleTrainingsPerWeekChange(4)}
      />
    </span>
    <span className="option">
    <label><span></span>5</label>
      <input
        className="radio__Input"
        type="radio"
        name="training"
        value="5"
        checked={trainingsPerWeek === 5}
        onChange={() => handleTrainingsPerWeekChange(5)}
      />
    </span>
    <span className="option">
    <label><span></span>6</label>
      <input
        className="radio__Input"
        type="radio"
        name="training"
        value="6"
        checked={trainingsPerWeek === 6}
        onChange={() => handleTrainingsPerWeekChange(6)}
      />
    </span>
    <span className="option">
    <label><span></span>7</label>
      <input
        className="radio__Input"
        type="radio"
        name="training"
        value="7"
        checked={trainingsPerWeek === 7}
        onChange={() => handleTrainingsPerWeekChange(7)}
      />
    </span>
  </div>
</div>

    <div className="box h">
      <button type="button" className="calorie__Button">{calculateBMR()} Calorias</button>
    </div>
    </div>
    <Footer/>
  </form>
  )
}

export default CalorieCalculator